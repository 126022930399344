import React, { useState, useEffect, useRef } from 'react';
import "./style.scss";
import { NMCKEditor } from "../CKEditor";
import { Button } from "../Button";
import { Menu } from "../Menu";
import { Dropdown, AutoComplete } from "../Input";
import { Header, Footer, HistoryTable } from "./Components";
import { getDocumenterPages, getTextFromHTML } from "./PageHelper";
import { FormDialog } from "../Dialog";

export const DocAuditTableCols = [
  { title: 'Action', dataIndex: 'action', key: 'action', width: '100px', className: 'exo2 f10 txt-left caps' },
  { title: 'Version', dataIndex: 'version_no', key: 'version_no', width: '100px', className: 'exo2 f10 txt-center' },
  { title: 'Name', dataIndex: 'username', key: 'username', width: '25%', className: 'exo2 f10 txt-left' },
  { title: 'Designation, Function', dataIndex: 'designation_function', key: 'designation_function', width: '48%', className: 'exo2 f10 txt-left' },
  { title: 'Date', dataIndex: 'created_at', key: 'created_at', width: '22%', className: 'exo2 f10 text-center' }
]
export const DocChangeTableCols = [
  { title: 'Action', dataIndex: 'action', key: 'action', width: '100px', className: 'exo2 f10 txt-left caps' },
  { title: 'Version', dataIndex: 'version', key: 'version', width: '100px', className: 'exo2 f10 txt-center' },
  { title: 'Responsible', dataIndex: 'responsible', key: 'responsible', width: '25%', className: 'exo2 f10 txt-left', },
  { title: 'Description', dataIndex: 'summary', key: 'summary', width: '48%', className: 'exo2 f10 txt-left', isHTML: true, render: 'html' },
  { title: 'Date', dataIndex: 'created_at', key: 'created_at', width: '22%', className: 'exo2 f10 text-center' }
]

const DocumentMenus = [
  { id: 'prev', label: "Move to Prev Page" },
  { id: 'next', label: "Move to Next Page" },
  { id: 'continue', label: "Cont. to Next Page" },
  { id: 'new', label: "Cont. to New Page" }
]
const DocHistoryRows = [
  { action: 'Approved', version: '', name: '[[CEOFullname]]', desc: 'Chief Executive Officer', date: '[[Ddd, dd-Mmm-yyyy]]' },
  { action: 'Approval', version: '', name: '[[ApproverFullname]]', desc: 'Head of Department, [[ApproverDept]]', date: 'Pending' },
  { action: 'Revision', version: '', name: '[[ReviewerFullname]]', desc: '', date: 'Review' },
  { action: 'Draft', version: '101', name: '[[DocCreatorFullname]]', desc: '[[FullDesignation]], InfoSec', date: '[[Ddd, dd-Mmm-yyyy]]' },
]
const ChangeHistoryDefaultRows = [
  { action: ' ', version: '', responsible: '', summary: '1.', created_at: '' }
]
const addContextMenu = (editor) => {
  const contextMenuDefinition = [
    {
      label: 'Custom Option 1',
      command: 'customOption1',
      group: 'customGroup',
    },
    {
      label: 'Custom Option 2',
      command: 'customOption2',
      group: 'customGroup',
    },
  ];
  editor.addCommand('customOption1', {
    exec: function (editorInstance) {
      console.log('Custom Option 1 clicked');
    },
  });

  editor.addCommand('customOption2', {
    exec: function (editorInstance) {
      console.log('Custom Option 2 clicked');
    },
  });
  editor.on('contentMenu', function (event) {
    event.data = contextMenuDefinition;
  });
}
const isTableSection = (section) => {
  return section.attribute === 'change_history' || section.attribute === 'doc_history'
}

const SectionEditor = React.forwardRef((props, ref) => {
  const { inEditMode, section, onChange, onEditorReady } = props;
  const [pageaction, setPageAction] = React.useState(null)
  useEffect(() => {
    if (inEditMode) {
      if (isTableSection(section) || section.attribute === 'tbl_of_content' || section.onlyTitle) {
        handleEditorReady({ editor: '' })
      }
    }
  }, [inEditMode])
  const handleEditorReady = (e) => {
    const editor = e.editor;
    if (editor) {
      ref.current = editor;
      addContextMenu(editor)
    }
    const { value, title, key, slNo, ...rest } = section
    onEditorReady && onEditorReady(rest, editor)
  }
  const getValue = () => {
    let value = section.value || '';
    if (!inEditMode) {
      let text = getTextFromHTML(value);
      if (section.isSubSection && text.length === 0) {
        value = ''
      }
    }
    return value;
  }
  const handleOnPageAction = (e) => {
    const { value } = e.target;
    if (value && value.id) {
      let data = ref.current ? ref.current.getData() : '';
      props.onMove && props.onMove(value.id, data)
    }
  }
  return (
    inEditMode ?
      <React.Fragment>
        <NMCKEditor
          type="inline"
          initialData={section.value}
          onChange={onChange}
          onInstanceReady={handleEditorReady} />
        <div className='row'>
          {
            Boolean(props.allowAddVariable && (section.allowAddVariable !== undefined ? section.allowAddVariable : true)) &&
            <Button label='Add Variable' className='bg0133CC btn-add' onClick={() => {
              props.onAddVariable && props.onAddVariable(section.attribute, ref.current)
            }} />
          }
          {
            !section.isSubSection &&
            <AutoComplete
              primary
              value={pageaction}
              name={`page-${section.attribute}`}
              placeholder="Action"
              caretClassName='c0033CC'
              options={DocumentMenus}
              className='page-action-select'
              labelClassName='hide'
              onChange={handleOnPageAction} />
          }
        </div>
      </React.Fragment>
      :
      <div dangerouslySetInnerHTML={{ __html: getValue() }} className='col w-100 cke_readonly'></div>
  )
})

const SubSection = (props) => {
  const { editable, docType, section, inEditMode } = props
  const ref = React.createRef(null)
  React.useEffect(() => {
    if (inEditMode && !editable) {
      const { value, title, key, slNo, ...rest } = section
      props.onEditorReady && props.onEditorReady(rest, '')
    }
  }, [inEditMode])
  const isEditable = (() => {
    let _editable = editable
    let value = section.value || '';
    if (!inEditMode && _editable) {
      let text = getTextFromHTML(value);
      if (section.isSubSection && text.length === 0) {
        _editable = false
      }
    }
    return _editable;
  })();
  return (
    <div className='col h-100 cke_toolbar'>
      {
        isEditable ?
          <SectionEditor
            ref={ref}
            {...props}
          />
          :
          <div className='col v-ctr h-ctr w-100 h-100 place-holder'>
            <span className='f14'>Place for {docType} blueprint</span>
          </div>
      }
    </div>
  )
}

const PageSection = (props) => {
  const { type, isSchemaOnly, section, inEditMode, onMove, onEditorReady } = props;
  const [menuElem, setMenuElem] = useState(null);
  const ref = useRef(null)
  useEffect(() => {
    if (inEditMode && (section.onlyTitle)) {
      const { value, title, key, slNo, ...rest } = section
      onEditorReady && onEditorReady(rest, null)
    }
  }, [inEditMode])
  const handleClick = (e, menu) => {
    setMenuElem(null);
    let val = ref.current ? ref.current.getData() : '';
    onMove && onMove(menu.id, val)
  }
  const getTableColumns = () => {
    return section.attribute === 'change_history' ? DocChangeTableCols : DocAuditTableCols
  }
  const getTableRows = () => {
    return (
      isSchemaOnly ? (
        section.attribute === 'change_history' ? ChangeHistoryDefaultRows : DocHistoryRows
      ) : (section.value || [])
    )
  }
  const getClassName = () => {
    let _className = 'col section w-100';
    _className += inEditMode ? ' edit-mode' : '';
    _className += section.isSubSection ? ' sub-section' : '';
    return _className;
  }
  const getStyle = () => {
    const style = {};
    if (section.isSubSection && section.height) {
      style.height = section.height;
    }
    return style
  }
  return (
    <div className={getClassName()} style={getStyle()}>
      <div className={`row w-100 v-start ${section.isSubSection ? 'h-100' : ''}`}>
        {
          Boolean(section.showSectionTitle && section.slNo) &&
          <strong className={`f7 bold exo2 ${isSchemaOnly ? 'c882222' : 'c238787'} caps slno`}>{section.slNo}.</strong>
        }
        <div className={`col f-rest ${section.isSubSection ? 'h-100' : ''}`} style={{ width: 673.1 }}>
          {
            section.showSectionTitle &&
            <div className={`row title`} id={`section-${section.attribute}`}>
              <strong className={`f7 bold exo2 ${isSchemaOnly ? 'c882222' : 'c238787'} caps`}>{section.title}</strong>
            </div>
          }
          {
            Boolean(section.isSubSection) &&
            <div className='col sub-section-content w-100 h-100'>
              {
                ['presentation', 'content'].map((docType) => {
                  return (
                    Boolean(section[docType]) &&
                    <SubSection
                      {...props}
                      docType={docType}
                      section={section}
                      editable={type === docType}
                    />
                  )
                })
              }
            </div>
          }
          {
            !section.onlyTitle && !section.isSubSection &&
            <div className={`col cke_toolbar ${section.attribute}`}>
              {
                (isTableSection(section) && getTableRows().length > 0) ?
                  <HistoryTable
                    isSchemaOnly={isSchemaOnly}
                    rows={getTableRows()}
                    Columns={getTableColumns()} />
                  : section.attribute === 'tbl_of_content' ?
                    <div dangerouslySetInnerHTML={{ __html: section.value || '' }}></div>
                    :
                    <SectionEditor
                      ref={ref}
                      {...props}
                    />
              }
            </div>
          }
        </div>
      </div>
      <Menu
        id={`${section.attribute}`}
        menuItems={DocumentMenus}
        anchorEl={menuElem}
        onMenuClick={handleClick}
        onClose={() => setMenuElem(null)} />
    </div>
  )
}

const Page = (props) => {
  const { isSchemaOnly, pageNo, document, sections, onSectionMove, onEditorReady, zoomLevel } = props;
  const [state, setState] = React.useState({ isPageOverFlow: false })
  const _pageRef = React.useRef(null)
  React.useEffect(() => {
    if (_pageRef.current) {
      checkForContentOverFlow()
      const observer = new MutationObserver(handleMutation);
      observer.observe(_pageRef.current, { childList: true, subtree: true });
    }
  }, [_pageRef.current])
  const handleMutation = () => {
    checkForContentOverFlow()
  }
  const checkForContentOverFlow = () => {
    const ele = _pageRef.current;
    if (ele) {
      const isOverFlow = ele.scrollHeight > ele.clientHeight;
      if (state.isPageOverFlow !== isOverFlow && !props.inEditMode) {
        setState((_) => ({ ..._, isPageOverFlow: isOverFlow }))
      }
    }
  }
  const handleSectionDataChange = (e) => {
    props.onChange && props.onChange(e);
    if (props.inEditMode && _pageRef.current) {
      const pageRect = _pageRef.current.getBoundingClientRect();
      for (let i = 0; i < sections.length; i++) {
        const { attribute } = sections[i];
        let secRec, sectionDom = _pageRef.current.querySelector(`.section.${attribute} .cke_toolbar .cke_editable`);
        if (sectionDom) {
          secRec = sectionDom.getBoundingClientRect();
        }
        if (pageRect && secRec) {
          const cssClassAdded = sectionDom.classList.contains('out-of-page')
          if (secRec.bottom > pageRect.bottom && !cssClassAdded) {
            sectionDom.classList.add('out-of-page')
            props.onPageOverFlow && props.onPageOverFlow(pageNo, sections[i])
            break;
          } else if (cssClassAdded) {
            sectionDom.classList.remove('out-of-page')
          }
        }
      }
    }
  }
  const getZoomLevel = () => {
    let style = {}, currentZoomLevel = zoomLevel;
    if (zoomLevel === 'fit') {
      let viewerContainer = window.document.querySelector('.document-viewer');
      if (viewerContainer) {
        currentZoomLevel = parseInt(((viewerContainer.clientWidth - 10) / 793) * 100);
      }
    }
    if (currentZoomLevel && currentZoomLevel !== 100) {
      let scale = currentZoomLevel / 100;
      let pageHeight = 29.7;
      style.transform = `scale(${scale})`;
      if (scale === 0.5 && pageNo === 0) {
        style.marginTop = '-7.4cm'
      } else {
        style.marginTop = pageHeight;
        if (pageNo === 0) {
          style.marginTop = (pageHeight / 2) + scale;
        }
        style.marginTop = `${style.marginTop * (scale - 1)}cm`
      }
      if (pageNo === (props.totalPages - 1)) {
        if (scale === 0.5) {
          style.marginBottom = '0px';
        } else {
          let rem = currentZoomLevel % 100;
          rem = rem ? rem : 100;
          style.marginBottom = `${4 * (rem / 25)}cm`;
        }
      }
    }
    return style
  }
  return (
    <div className={`col page ${isSchemaOnly ? 'bgFFDDDD' : 'bgDDFFFF'}`} id={`page-${pageNo + 1}`} style={getZoomLevel()}>
      <Header {...props} pageNo={pageNo + 1} />
      <div className='col f-rest o-hide'>
        <div className={`col w-100 h-100 oy-auto page-content ${state.isPageOverFlow ? 'bgCFE3333' : ''}`} ref={_pageRef}>
          {
            pageNo === 0 &&
            <h1 className={`exo2 bold f2 ${isSchemaOnly ? 'c882222' : 'c238787'}  text-center doc-title`}>{!isSchemaOnly ? document.name : '[[DocumentTitle]]'}</h1>
          }
          {
            sections.map((section, index) => {
              return (
                <PageSection
                  {...props}
                  key={section.attribute}
                  section={section}
                  onChange={handleSectionDataChange}
                  onMove={(action, value) => {
                    onSectionMove && onSectionMove(action, value, index)
                  }}
                  onEditorReady={(sectionConfig, editor) => {
                    onEditorReady && onEditorReady(pageNo, index, sectionConfig, editor)
                  }}
                />
              )
            })
          }
        </div>
      </div>
      <Footer  {...props} pageNo={pageNo + 1} />
    </div>
  )
}


export const DocumentEditor = React.forwardRef((props, ref) => {
  const { type, document, user, isSchemaOnly, zoomLevel, onChange, sections, initialPages } = props;
  const [pages, setPages] = useState(null)
  const [pageOverflow, setPageOverflow] = React.useState(null)
  const sectionEditors = useRef([])
  useEffect(() => {
    if (document && !pages) {
      const options = { document, user, isSchemaOnly, sections, initialPages, type }
      let pages = getDocumenterPages(options);
      setPages(pages)
    }
  }, [document]);
  useEffect(() => {
    if (document && sections && pages) {
      updateSectionTitles()
    }
  }, [document])
  const updateSectionTitles = () => {
    let shouldUpdate = false
    for (let i = 0; i < pages.length; i++) {
      for (let j = 0; j < pages[i].length; j++) {
        const { title, attribute, showSectionTitle } = pages[i][j]
        if (showSectionTitle) {
          if (attribute === 'common_doc_type' && title === '[[DocumentType]]' && document.doc_type) {
            pages[i][j].title = document.doc_type.label;
            shouldUpdate = true
          }
        }
      }
    }
    if (shouldUpdate) {
      setPages(pages);
    }
  }
  const getDocumentData = () => {
    let body = {}, pageConfig = [];
    sectionEditors.current.forEach((sections, index) => {
      pageConfig[index] = []
      sections.forEach((section, j) => {
        const { editor, ...rest } = section;
        let attribute = section.attribute;
        let key = `${index}_${j}`;
        if (rest.isSubSection) {
          attribute = section.mainAttribute;
          key += `_${section.attribute}`
        }
        pageConfig[index][j] = { ...rest, key };
        if (!body[attribute]) {
          body[attribute] = {};
        }
        if (!rest.onlyTitle) {
          body[attribute][key] = (editor && editor.getData) ? editor.getData() : ''
        }
      })
    });
    body.page_config = JSON.stringify(pageConfig);
    // console.log("body", body);
    return body
  }
  const handleChange = (e) => {
    onChange && onChange(e)
  }
  const handleEditorReady = (page, index, sectionConfig, editor) => {
    if (!sectionEditors.current[page]) {
      sectionEditors.current[page] = [];
    }
    sectionEditors.current[page][index] = { ...sectionConfig, editor: editor };
  }
  const handleEditorSectionMoved = (action, pageNo, index) => {
    switch (action) {
      case 'remove':
        sectionEditors.current[pageNo].splice(index, 1);
        if (sectionEditors.current[pageNo].length === 0) {
          sectionEditors.current.splice(pageNo, 1);
        }
        break;
      case 'add-begin':
        if (!sectionEditors.current[pageNo]) {
          sectionEditors.current[pageNo] = [];
        }
        sectionEditors.current[pageNo].unshift({})
        break;
      case 'add-end':
        if (!sectionEditors.current[pageNo]) {
          sectionEditors.current[pageNo] = [];
        }
        sectionEditors.current[pageNo].push({})
        break;
    }
  }
  const handlePageOverflow = (pageNo, section) => {
    setPageOverflow({ pageNo, section })
  }
  const handleCloseOverflowWarning = () => {
    setPageOverflow(null)
  }
  const handleSectionMove = (action, value, sectionIndex, pageNo) => {
    const currentPages = pages.map((sections) => {
      return sections.map(_ => ({ ..._ }))
    });
    let section = currentPages[pageNo][sectionIndex], newPageNo, newSection;
    switch (action) {
      case DocumentMenus[0].id:
        currentPages[pageNo].splice(sectionIndex, 1);
        newPageNo = pageNo - 1;
        currentPages[newPageNo].push({ ...section, value: value });
        if (currentPages[pageNo].length === 0) {
          currentPages.splice(pageNo, 1);
        }
        handleEditorSectionMoved('remove', pageNo, sectionIndex)
        handleEditorSectionMoved('add-end', newPageNo)
        break;
      case DocumentMenus[1].id:
        currentPages[pageNo].splice(sectionIndex, 1);
        newPageNo = pageNo + 1;
        if (!currentPages[newPageNo]) {
          currentPages.push([{ ...section, value: value }])
        } else {
          currentPages[newPageNo].unshift({ ...section, value: value })
        }
        handleEditorSectionMoved('remove', pageNo, sectionIndex)
        handleEditorSectionMoved('add-begin', newPageNo)
        break;
      case DocumentMenus[2].id:
        newSection = { ...section, showSectionTitle: false, value: '' }
        currentPages.forEach((sections, index) => {
          let found = sections.find((_) => _.attribute === section.attribute);
          if (found) {
            newPageNo = index;
          }
        })
        newPageNo++;
        if (!currentPages[newPageNo]) {
          currentPages.push([newSection])
        } else {
          currentPages[newPageNo].unshift(newSection)
        }
        handleEditorSectionMoved('add-begin', newPageNo)
        break;
      case DocumentMenus[3].id:
        newPageNo = pageNo;
        currentPages.forEach((sections, index) => {
          let found = sections.find((_) => _.attribute === section.attribute);
          if (found) newPageNo = index;
        })
        newPageNo++;
        currentPages.splice(newPageNo, 0, []);
        sectionEditors.current.splice(newPageNo, 0, [])
        newSection = { ...section, showSectionTitle: false, value: '' }
        currentPages[newPageNo].push(newSection);
        handleEditorSectionMoved('add-begin', newPageNo)
        break;
    };
    setPages(currentPages)
  }
  React.useImperativeHandle(ref, () => ({ getDocumentData: getDocumentData }))
  return (
    <div className={`col w-100 h-100 oy-auto document-viewer ${type}`}>
      {
        Boolean(pages) &&
        <React.Fragment>
          {
            pages.map((sections, index) => {
              return (
                <Page
                  {...props}
                  key={index}
                  zoomLevel={zoomLevel}
                  sections={sections}
                  pageNo={index}
                  totalPages={pages.length}
                  onEditorReady={handleEditorReady}
                  onChange={handleChange}
                  onPageOverFlow={handlePageOverflow}
                  onSectionMove={(action, value, sectionIndex) => handleSectionMove(action, value, sectionIndex, index)}
                />
              )
            })
          }
        </React.Fragment>
      }
      {
        Boolean(pageOverflow) &&
        <FormDialog
          title='Use New Page!'
          className='discard-modal'
          titleClass='f6 cFE3333 med'
          onClose={handleCloseOverflowWarning}
          rightBtn={{ label: "Okay", onClick: handleCloseOverflowWarning }}>
          <p className='f14 line-22'>
            Page {pageOverflow.pageNo + 1} is filled. Move the remaining content to the next page.<br />
            Use the "Continue to New Page" option.
          </p>
        </FormDialog>
      }
    </div>
  )
})