import React, { useState, useRef, useEffect } from 'react';
import { Link, useParams, useNavigate, json } from 'react-router-dom';
import { Button, TextInput, Form, FormDialog, useNavigateBack, Menu, Dropdown, DocumentEditor, OvalLoading } from "../../../components";
import { useSelector, useDispatch } from "react-redux";
import { ArchiveDeleteDialog } from "./ArchiveDelete";
import { UpdateHistoryDialog } from "./History";
import { SearchVariable } from "../../Common";
import {
  getDepartments, getDocTypes, getDocClassifications, docActions, isActive, isDraft, isApproved, STATUS,
  getShowBlueprint, userActions, getReviewers, isArchived, getNewDocumentId, getCurrentUser, SECTION_SIZE
} from "../../../store";
import { EllipsisOutlined } from '@ant-design/icons';

// Menus
const ArchiveMenu = [
  { dialogId: 'archive', label: "Archive", icon: 'icon-open-folder', color: 'cFE3333', selection: true },
  { dialogId: 'delete', label: "Delete", icon: 'icon-delete', color: 'cFE3333', selection: true },
]
const UnarchiveMenu = [
  { dialogId: 'unarchive', label: "Unarchive", icon: 'icon-open-folder', color: 'cFE3333', selection: true },
  { dialogId: 'delete', label: "Delete", icon: 'icon-delete', color: 'cFE3333', selection: true },
]
const StatusMenus = [
  { dialogId: 'status', label: "Approve", icon: 'icon-send', selection: true, color: '' },
  { dialogId: 'status', label: "Activate", icon: 'icon-send', selection: true, color: '' },
]
const ZoomLevels = [
  { label: '50%', value: 50 },
  { label: '100%', value: 100 },
  { label: '125%', value: 125 },
  { label: '150%', value: 150 },
  { label: 'Fit', value: 'fit' },
]

//Fieds
const DefaultFormData = {
  name: "Document Name"
}
const Fields = [
  { label: 'Department', attribute: 'department_name', placeholder: "Department", required: true, showColon: true, type: 'select', labelClassName: 'w-115pix' },
  { label: 'Type', attribute: 'doc_type', placeholder: "Type", type: 'select', required: true, showColon: true, labelClassName: 'w-70pix' },
  { label: 'Classification', attribute: 'classification', placeholder: "Classification", type: 'select', required: true, showColon: true, labelClassName: 'w-115pix' },
  { label: 'ID', attribute: 'doc_id', placeholder: "ID", type: 'text', required: true, showColon: true, disabled: true, labelClassName: 'w-70pix' },
  { label: 'Internal Notes', className: 'note-input', attribute: 'notes', placeholder: "Internal Notes", type: 'html', required: false, showColon: true, rows: 5, cols: 10, labelClassName: 'w-115pix' }
]

const SendForReview = [
  { label: 'Approver', attribute: 'reviewer_email', type: 'select', placeholder: 'Search Approver', required: true, showColon: true }
]
const PresentationSections = [
  { attribute: 'doc_history', defaultPage: 0, },
  { attribute: 'doc_control', defaultPage: 0, },
  { attribute: 'copyright', defaultPage: 0 },
  { attribute: 'tbl_of_content', defaultPage: 1, allowAddVariable: false },
  { attribute: 'purpose', defaultPage: 2 },
  { attribute: 'scope', defaultPage: 2 },
  { attribute: 'organisation_identifier', defaultPage: 2 },
  { attribute: 'common_doc_type', defaultPage: 3 },
  { attribute: 'enforcement', defaultPage: 4 },
  { attribute: 'exception', defaultPage: 4 },
  { attribute: 'violation', defaultPage: 4 },
  { attribute: 'review', defaultPage: 4 },
  { attribute: 'appendix', defaultPage: 5 },
]
const ContentSections = [
  { attribute: 'purpose', defaultPage: 0 },
  { attribute: 'scope', defaultPage: 0 },
  { attribute: 'organisation_identifier', defaultPage: 0 },
  { attribute: 'common_doc_type', defaultPage: 1 },
  { attribute: 'enforcement', defaultPage: 2 },
  { attribute: 'exception', defaultPage: 2 },
  { attribute: 'violation', defaultPage: 2 },
  { attribute: 'review', defaultPage: 2 },
  { attribute: 'appendix', defaultPage: 3 },
]

export const DocContentsNewScreen = (props) => {
  const { edit, readOnly, type, create } = props;
  const isContent = type === 'content'
  const { blueprintId } = useParams()
  const navigateBack = useNavigateBack()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const user = useSelector(getCurrentUser)
  const blueprint = useSelector(getShowBlueprint)
  const departments = useSelector(getDepartments)
  const reviewers = useSelector(getReviewers)
  const types = useSelector(getDocTypes)
  const classifs = useSelector(getDocClassifications)
  const docId = useSelector(getNewDocumentId)
  const [state, setState] = useState({
    searchVariable: '', modified: false, showVaribleList: false, zoomLevel: ZoomLevels[1].value,
    formData: { ...(blueprintId ? blueprint : DefaultFormData) }, showDialog: '', reviewer_email: '',
    show: !(edit || readOnly), additionalPages: {}
  })
  const ckRefs = useRef({})
  const varEditor = useRef(null)
  const _docRef = useRef(null);
  // const _formData = useRef({ ...(blueprintId ? blueprint : DefaultFormData) })
  useEffect(() => {
    if (departments && types && classifs) {
      if (create) {
        setState((_) => ({ ..._, show: true }))
      } else if (blueprintId) {
        dispatch(docActions.fetchBlueprint({ id: blueprintId, isContent }))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departments, types, classifs])
  useEffect(() => {
    if (blueprintId) {
      if (blueprint) {
        if (blueprint.status === 'deleted' || blueprint.message === 'Record Not Found') {
          return navigate('/page-not-found')
        }
        const formData = { ...state.formData }
        formData.name = blueprint.name;
        if (isContent) {
          Fields.forEach(({ attribute }) => {
            if (blueprint[attribute]) {
              formData[attribute] = blueprint[attribute]
            }
          })
        }
        setState((_) => ({ ..._, formData: { ...formData }, show: true }));
      } else {
        setState((_) => ({ ..._, show: false }))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blueprint])
  useEffect(() => {
    if (docId && state.formData.doc_id !== docId) {
      setState((_) => ({ ..._, formData: { ..._.formData, doc_id: docId } }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docId])
  const getFields = () => {
    let fields = Fields.slice(0, Fields.length - 1)
    return fields.map((_) => ({ ..._, readOnly: readOnly }))
  }
  const getMenu = () => {
    if (blueprint) {
      if (isArchived(blueprint)) {
        return UnarchiveMenu
      } else {
        let menu = [];
        if (isDraft(blueprint) && blueprint.approver_id === user.id) {
          menu.push(StatusMenus[0])
        } else if (isApproved(blueprint)) {
          menu.push(StatusMenus[1])
        }
        return [...menu, { isEmpty: true }, ...ArchiveMenu]
      }
    }
    return null
  }
  const handleValueChange = (e) => {
    const { name, value } = e.target;
    const formData = { ...state.formData }
    formData[name] = value;
    if (name === 'doc_type' && !edit) {
      dispatch(docActions.fetchNewDocId(value.id))
    }
    setState((_) => ({ ..._, formData, modified: true }))
  }
  const getOptions = (attribute) => {
    // eslint-disable-next-line default-case
    switch (attribute) {
      case 'department_name': return departments
      case 'doc_type': return types
      case 'classification': return classifs
      case 'reviewer_email': return reviewers
    }
  }
  const getSections = () => {
    let sections = [], { doc_type } = state.formData;
    if (isContent) {
      sections = ContentSections.map((_) => ({ ..._ }))
      sections[2].title = `Organisation Identifier: "[[OrgShortname]]":`
      if (doc_type) {
        sections[3].title = `${doc_type.label}:`
      }
    } else {
      sections = PresentationSections.map((_) => ({ ..._ }))
    }
    return sections
  }
  const handleEditorInstance = (attribute, e) => {
    ckRefs.current[attribute] = e.editor;
    if (Boolean(edit && blueprint)) {
      e.editor.setData(blueprint[attribute])
    }
  }
  const handleAddVariable = (attribute, editor) => {
    varEditor.current = ckRefs.current[attribute] ? ckRefs.current[attribute].current : editor;
    setState((_) => ({ ..._, selectedSection: attribute, searchVariable: '' }))
    // const selection = editor.getSelection().getRanges()[0]
    // console.log('editor', selection);
  }
  const handleCopyVariable = (variable) => {
    try {
      const editor = varEditor.current ? varEditor.current : ckRefs.current[state.selectedSection]
      if (editor) {
        editor.insertText(variable.name)
      }
      setState((_) => ({ ..._, selectedSection: '' }))
    } catch (error) {
      console.log(error);
    }
  }
  const getBlueprintBody = () => {
    let body = { name: state.formData.name }, docBody = {};
    if (isContent) {
      Fields.forEach((field) => {
        if (field.type === 'html') {
          let editor = ckRefs.current[field.attribute];
          if (editor) {
            body[field.attribute] = editor.getData()
          }
        } else {
          body[field.attribute] = state.formData[field.attribute]
          if (field.type === 'select') {
            body[field.attribute] = body[field.attribute].id
          }
        }
      })
    }
    if (_docRef.current) {
      docBody = _docRef.current.getDocumentData();
    }
    body = { ...body, ...docBody }
    return body;
  }
  const handleNavBack = () => {
    navigateBack()
  }
  const handleSave = () => {
    if (edit && blueprintId) {
      setState((_) => ({ ..._, showDialog: 'history', savePresentation: true, updateHistory: { type: 'minor', summary: '' } }))
    } else {
      const body = getBlueprintBody();
      if (isContent) {
        dispatch(docActions.createContentBlueprint({ blueprint: body }))
      } else {
        dispatch(docActions.createPresentationBlueprint({ presentation: body }))
      }
      handleDiscardDialog(true, null, true)
    }
  }
  const handleBack = () => {
    if (!readOnly && state.modified && edit) {
      return setState((_) => ({ ..._, showDialog: 'discord' }))
    }
    handleNavBack()
  }
  const handleDiscardDialog = (back, e, isCreate) => {
    const showDialog = state.showDialog;
    setState((_) => ({ ..._, showDialog: '' }))
    if (back) {
      if (showDialog === 'delete' || showDialog === 'archive' || showDialog === 'unarchive' || isCreate) {
        let tab = ''
        if (isCreate) {
          tab = isContent ? '/C?tab=draft' : '/P';
        } else if (isArchive) {
          tab = `/A?tab=${isContent ? 'content' : 'presentation'}`
        } else {
          tab = isContent ? '/C' : '/P';
          if (isContent) {
            tab += '?tab=' + (isActive(blueprint) ? 'active' : 'draft');
          }
        }
        setTimeout(() => {
          navigate("/D" + tab)
        }, 200)
      } else {
        handleNavBack()
      }
    }
  }
  const handleCKChange = () => {
    if (!state.modified) {
      setState((_) => ({ ..._, modified: true }))
    }
  }
  const getBlueprintForEdit = () => {
    if (create) {
      return state.formData
    }
    return blueprint
  }
  const handleSaveWithUpdate = (htmlDesc, textDesc) => {
    const body = getBlueprintBody()
    let updateHistory = { summary: htmlDesc };
    if (isApproved(blueprint)) {
      body.status = STATUS.DRAFT;
    }
    if (isContent) {
      dispatch(docActions.updateBlueprint({ blueprint: body, updateHistory, id: blueprint.id }))
    } else {
      dispatch(docActions.updatePresentation({ presentation: body, updateHistory, id: blueprint.id }))
    }
    handleNavBack()
    setState((_) => ({ ..._, showDialog: '', modified: false }))
  }
  const handleSendForReview = (e) => {
    dispatch(docActions.sendDocForReview({ id: blueprint.id, email: state.reviewer_email.id, isContent }))
    setState((_) => ({ ..._, showDialog: '', reviewer_email: null }))
  }
  const handleDocsMenu = (e) => {
    setState((_) => ({ ..._, showMenu: e.target }))
  }
  const handleMenuClick = (e, menu) => {
    setState((_) => ({ ..._, showMenu: null, showDialog: menu.dialogId }))
  }
  const enableSave = () => {
    let enabled = false;
    if (state.modified) {
      const { formData } = state;
      enabled = formData.name.length > 0
      if (isContent) {
        Fields.every(field => {
          if (field.required && !formData[field.attribute]) {
            enabled = false
          }
          return enabled
        })
      }
      if (!enabled && isContent) {
        getSections().every(section => {
          const data = ckRefs.current[section.attribute] ? ckRefs.current[section.attribute].getData() : null
          if (!data) {
            enabled = false
          }
          return enabled
        })
      }
    }
    return enabled
  }
  const handleUpdateStatus = () => {
    handleDiscardDialog(true);
    const status = isDraft(blueprint) ? STATUS.APPROVED : STATUS.ACTIVE;
    const message = `Document status has been ${isDraft(blueprint) ? 'Approved' : 'Activated'}`;
    dispatch(docActions.updateStatus({ id: blueprint.id, status, isContent, message }))
  }
  const menu = getMenu()
  const isArchive = isArchived(blueprint)
  const fields = getFields();
  return (
    <div className='col w-100 h-100 o-hide screen-pad new-content doc'>
      {
        state.show ?
          <React.Fragment>
            <div className='row header h-btn'>
              <div className='row'>
                <Button icon="f1 icon-back c00085" variant='lite' onClick={handleBack} />
                <TextInput name='name' placeholder='Document Name' readOnly={readOnly} font='f7 exo2' className='title-input' value={state.formData.name} onChange={handleValueChange} />
              </div>
              <div className='row'>
                <Dropdown
                  onChange={(e) => {
                    setState((_) => ({ ..._, zoomLevel: e.target.value }))
                  }}
                  name='zoom' caretClassName='c0033CC' className='mode-select zoom'
                  primary value={state.zoomLevel} options={ZoomLevels} />
                {
                  readOnly && !isArchive && !edit && !isActive(blueprint) &&
                  <Link to='edit' className='btn line-22 bg0133CC'>
                    <span className='f9 cFFF'>Edit</span>
                  </Link>
                }
                {
                  ((!isArchive && edit) || create) && !isActive(blueprint) &&
                  <Button label='Save' disabled={!enableSave()} onClick={handleSave} />
                }
                {
                  ((edit || readOnly) && !isArchive) && blueprint && isDraft(blueprint) &&
                  <Button
                    label='Send for Approval'
                    onClick={() => {
                      setState((_) => ({ ..._, showDialog: 'approver' }))
                      dispatch(userActions.fetchReviewers())
                    }} />
                }
                {
                  Boolean(menu) &&
                  <Button className='col v-ctr h-ctr dot-menu btn-menu' iconcomponent={<EllipsisOutlined />} variant='lite' onClick={handleDocsMenu} />
                }
                <Menu
                  menuItems={menu}
                  anchorEl={state.showMenu}
                  onMenuClick={handleMenuClick}
                  onClose={() => handleDocsMenu({ target: null })} />
              </div>
            </div>
            <div className='col f-rest oy-auto'>
              {
                isContent ?
                  <React.Fragment>
                    <div className='row w-100 field-row'>
                      <Form
                        getOptions={getOptions}
                        fieldClass={`field-input`}
                        formData={state.formData}
                        Fields={fields}
                        onChange={handleValueChange} />
                    </div>
                    <div className="col field-row" style={{ width: '100%' }}>
                      <Form
                        getOptions={getOptions}
                        fieldClass={`field-input`}
                        formData={state.formData}
                        Fields={[{ ...Fields[Fields.length - 1], readOnly }]}
                        onInstanceReady={handleEditorInstance}
                      />
                    </div>
                    <div className="col w-100">
                      <DocumentEditor
                        ref={_docRef}
                        type='content'
                        allowAddVariable
                        isSchemaOnly
                        user={user}
                        sections={getSections()}
                        inEditMode={!readOnly}
                        classifications={classifs}
                        onChange={handleCKChange}
                        zoomLevel={state.zoomLevel}
                        onAddVariable={handleAddVariable}
                        document={getBlueprintForEdit()} />
                    </div>
                  </React.Fragment>
                  :
                  <DocumentEditor
                    ref={_docRef}
                    type='presentation'
                    allowAddVariable
                    isSchemaOnly
                    user={user}
                    sections={getSections()}
                    inEditMode={!readOnly}
                    classifications={classifs}
                    onChange={handleCKChange}
                    zoomLevel={state.zoomLevel}
                    onAddVariable={handleAddVariable}
                    document={getBlueprintForEdit()}
                  />
              }
            </div>
            {
              Boolean(state.selectedSection) &&
              <SearchVariable
                onClose={handleAddVariable.bind(null, null)}
                onCopy={handleCopyVariable}
              />
            }
            {
              state.showDialog === 'discord' &&
              <FormDialog
                title='Discard Changes?'
                className='discard-modal'
                titleClass='f6 cFE3333 med'
                onClose={handleDiscardDialog.bind(null, false)}
                rightBtn={{ label: "Discard", className: 'bgCFE3333', onClick: handleDiscardDialog.bind(null, true) }}
                leftBtn={{ label: "Save", color: '#0033CC', variant: 'lite', onClick: handleSave }} />
            }
            {
              state.showDialog === 'history' &&
              <UpdateHistoryDialog
                blueprint={blueprint}
                onSave={handleSaveWithUpdate}
                onClose={handleDiscardDialog.bind(null, false)}
              />
            }
            {
              state.showDialog === 'approver' &&
              <FormDialog
                title='Send for Approval'
                className='approval-dialog'
                dialogClassName={`archive-dialog-width`}
                onClose={handleDiscardDialog.bind(null, false)}
                leftBtn={{ label: "Cancel", color: '#0033CC', variant: 'lite', onClick: handleDiscardDialog.bind(null, false) }}
                rightBtn={{ label: "Send", onClick: handleSendForReview }} >
                <div className='col content'>
                  <Form
                    fieldClass='approval-input'
                    formData={{ reviewer_email: state.reviewer_email }}
                    Fields={SendForReview}
                    getOptions={getOptions}
                    onChange={(e) => {
                      // console.log('e.target.value', e.target.value);
                      setState((_) => ({ ..._, reviewer_email: e.target.value }))
                    }}
                  />
                </div>
              </FormDialog>
            }
            {
              Boolean(state.showDialog === 'delete' || state.showDialog === 'archive' || state.showDialog === 'unarchive') &&
              <ArchiveDeleteDialog
                user={user}
                blueprint={blueprint}
                isContent={isContent}
                isDelete={state.showDialog === 'delete'}
                onClose={handleDiscardDialog} />
            }
            {
              state.showDialog === 'status' &&
              <FormDialog
                className={`status-popup`}
                title={isDraft(blueprint) ? 'Approve this Blueprint?' : "Activate this Blueprint?"}
                onClose={handleDiscardDialog.bind(null, false)}
                leftBtn={{ label: "Cancel", color: '#0033CC', variant: 'lite', onClick: handleDiscardDialog.bind(null, false) }}
                rightBtn={{ label: 'Yes, Confirm', className: 'bgActive', onClick: handleUpdateStatus }}>
                {
                  isDraft(blueprint) ?
                    <p className='f7 c00085'>
                      This version of the Blueprint will be ready for activation. <br /><br />
                      Your Name, Designation & Department will be recorded along with today's Date.<br />
                    </p>
                    :
                    <p className='f7 c00085'>
                      This version of the Blueprint will be ready for activation.<br />
                      Further changes WILL NOT permitted.<br /><br />
                      Any further changes to this Blueprint can be done only in the next Draft version.<br />
                      Currently Active version (if any) will be archived.
                    </p>
                }
              </FormDialog>
            }
          </React.Fragment>
          :
          <OvalLoading />
      }
    </div>
  )
}